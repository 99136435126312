<template>
  <div class="section-footer px-3 px-sm-4 pt-5">
    <h1>Thank You</h1>
    <span class="fs-2 text-handwrite">
      {{ couple.female.nickname }} & {{ couple.male.nickname }}
    </span>
    <!-- <div class="section-footer-logo w-75 my-4 p-4">
      <h4 class="text-center">
        Design with
        <HeartFill /> by :
      </h4>
      <img
        src="@/assets/logos/logo-hadirya.png"
        alt="hadirya"
        class="img-fluid"
      />
    </div>
    <div class="section-show gap-2 mt-2 mb-5">
      <div
        class="d-flex justify-content-center align-items-center flex-row gap-2"
      >
        <a
          href="https://www.instagram.com/hadirya.id/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstaIc height="25" width="25" />
        </a>
        <a href="https://hadirya.id/" target="_blank" rel="noopener noreferrer">
          <WebIc height="28" width="28" />
        </a>
      </div>
      <a href="https://hadirya.id" target="_blank" rel="noopener noreferrer">
        hadirya.id
      </a>
    </div> -->
  </div>
</template>

<script>
// const HeartFill = () => import("@/assets/icons/icon-heart-filled.svg");
// import InstaIc from "@/assets/icons/icon-instagram.svg";
// import WebIc from "../../assets/icons/icon-web.svg";

export default {
  name: "FooterSection",
  props: ["couple"],
  // components: { HeartFill, InstaIc, WebIc },
};
</script>

<style>
</style>